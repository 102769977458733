.jitsi-meet
{
    height: 400px !important;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
}

/* Zoom container styles */
.zoom-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;
  max-height: 800px;
  margin: 0 auto;
  background: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
}

/* Media queries for responsive design */
@media (max-width: 1200px) {
  .zoom-container {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0.5rem;
  }
  
  .zoom-container {
    height: 100%;
    min-height: 400px;
  }
}

@media (max-width: 480px) {
  .zoom-container {
    height: 60vh;
    min-height: 300px;
  }
}

/* Ensure Zoom UI toolkit elements are properly centered */
.zoom-container > div {
  width: 100% !important;
  height: 100% !important;
}

/* Fix for video elements */
.zoom-container video {
  object-fit: cover;
}